<template>
	<div>
		<div style="height: 40px">
			<a-alert style="width: 100%" ref="alert1" banner closable message="此店铺备用金用于电子邀请函，短信或第三方预订消耗，不可提现。" type="error" class="info" v-if="showInfo" :after-close="() => (this.showInfo = false)">
				<template slot="icon">
					<a-icon type="info-circle" style="display: flex" />
				</template>
			</a-alert>
		</div>
		<div class="content-wrap table-warp">
			<div class="flex justify-center items-center">
				<div class="desc flex items-center">
					店铺备用金
					<a-icon type="info-circle" style="font-size: 18px; margin-left: 12px; cursor: pointer" @click="showAlert" />
				</div>
				<div class="balance">{{ corp_money }} <span>元</span></div>
				<a-button type="primary" size="large" style="width: 88px; height: 32px" @click="qrCodeVisible = true"> 充值 </a-button>
			</div>
			<div class="flex justify-center items-center">
				<div class="desc flex items-center">短信模板选择：{{ hasCustom ? readDicFn(customStatus, titleOptions) : templateChecked.name }}</div>
				<!-- <a-button
          type="primary"
          size="large"
          style="height: 32px; margin-left: 50px"
          @click="templateVisible = true"
        >
          重新选择
        </a-button> -->

				<a-dropdown :trigger="['click']" class="dropDownMenu">
					<a-button type="danger" ghost @click="e => e.preventDefault()">
						{{ hasCustom ? '自定义短信' : templateChecked.name }}
						<a-icon type="caret-down" />
					</a-button>
					<a-menu slot="overlay">
						<a-menu-item>
							<a href="javascript:;" @click="selectTemplate">尊享预订回执</a>
						</a-menu-item>
						<a-menu-item>
							<a href="javascript:;" @click="openCustomModal">自定义短信</a>
						</a-menu-item>
					</a-menu>
				</a-dropdown>
			</div>
		</div>
		<div class="table-warp">
			<div class="table-title">消耗明细</div>
			<a-table :columns="columns" :data-source="list" rowKey="id" :pagination="pagination" @change="handleTableChange" />
		</div>
		<a-modal width="680px" :visible.sync="qrCodeVisible" title="店铺备用金充值" @cancel="visibleCancel" @ok="visibleCancel">
			<template>
				<div class="qr-code mx-auto">
					<a-spin :spinning="qrLoading">
						<img :src="qrCode || require('@/assets/qrcode-empty.png')" alt="二维码" class="w-full cursor-pointer" @click="getQRCode(1)" />
					</a-spin>
				</div>
				<div class="pay-tips mx-auto">
					<a-icon type="scan" style="font-size: 30px; margin-right: 8px" />
					<div>请使用微信扫一扫 扫描二维码支付</div>
				</div>
			</template>
		</a-modal>

		<a-modal v-model="templateVisible" :footer="null" width="680px">
			<h1 class="title_h1">短信模版选择</h1>
			<span class="tips">短信0.1元/条，每 67 字计算为 1条短信（包含标点符号和空格），实际计费请查看消耗明细。</span>
			<a-divider />
			<a-row type="flex" justify="center" align="middle">
				<a-col span="12" v-for="item in templateList" :key="item.id">
					<div class="message-template">
						<img :src="item.imgUrl" :alt="item.name" />
						<img alt="选中" v-if="templateChecked.id === item.id" class="message-template-checked" src="@/assets/message-template-checked.png" />
					</div>
					<div class="flex justify-center mt-6">
						<a-button type="primary" class="message-template-button" @click="setMessageTemplate(item.id)"> 使用 </a-button>
					</div>
				</a-col>
			</a-row>
		</a-modal>

		<!-- S 编辑弹窗 自定义短信 -->
		<a-modal width="1130px" v-model="customVisible" title="自定义短信" class="customVisible" okText="提交审核" :confirmLoading="customOkLoading" @ok="submitCustom" @cancel="customCancel">
			<a-row :gutter="[15, 15]">
				<a-col :span="12">
					<!-- <div
            id="customDom"
            ref="customDom"
            class="custom_template custom_template_left"
            data-title="【订个包间】"
            contenteditable
            @keydown="contextKeyDown"
            @input="setNoteLength"
            @paste="contextPaste"
          >&nbsp;</div> -->
					<div data-title="【订个包间】" class="custom_template_text" id="customDom">
						<textarea ref="customDom" v-model="customInitTemplate" @input="setNoteLength" />
					</div>
				</a-col>
				<a-col :span="12">
					<div class="custom_template">
						<p class="custom_template_title">选择短信变量：</p>
						<div class="custom_template_space">
							<a-button v-for="(item, index) in customBtn" :key="index" @click="customBtnClickNew(item.value)">{{ item.value }}</a-button>
						</div>
					</div>
				</a-col>
				<a-col :span="24">
					<div class="custom_hint">
						短信字数含“签名+模板内容+变量内容”，短信每 67 字符计算为 1 条短信（包含标点符号和空格）。此模板包含
						{{ noteLength }}个字符，需要 {{ noteNum }} 条短信，预计使用{{ noteMoney }}元，实际计费请查看资金管理消耗明细。
					</div>
				</a-col>
				<a-col :span="24">
					<div class="custom_hint">请注意：自定义短信需要人工审核，模板审核时间一般为每日：上午9：00-凌晨12：00，一般为1-5分钟以内审核完成。</div>
				</a-col>
			</a-row>
		</a-modal>
		<!-- E 编辑弹窗 自定义短信 -->
	</div>
</template>

<script>
import {getMessageTemplate, getTopupList, setMessageTemplate, workSmsTemplateGet, workSmsTemplateUpadte} from '@/api/moneyManage'
import {getWorkMakeMiniQrcode} from '@/api/mobileWorkbench'
import {mapState} from 'vuex'
import {checkDataType, readDicFn} from '@/utils/utils'
import Bigjs from 'big.js'

export default {
	name: 'MoneyManage',
	data() {
		return {
			showInfo: true,
			qrCodeVisible: false,
			qrLoading: false,
			qrCode: '',

			// nbsp: null, // 存储空节点
			customVisible: false, // 自定义短信模板 visible
			customOkLoading: false, // 自定义短信模板按钮loading
			hasCustom: false,
			customStatus: 1,
			customInitTemplate: '',
			customBtn: [], // 自定义短信模板btn
			noteLength: 0,

			templateVisible: false,
			templateChecked: {},
			templateList: [
				// {
				//   id: 1,
				//   name: "普通预订回执",
				//   imgUrl: require("@/assets/message-template-1.png"),
				// },
				// {
				//   id: 2,
				//   name: "高级预订回执",
				//   imgUrl: require("@/assets/message-template-2.png"),
				// },
				{
					id: 3,
					name: '尊享预订回执',
					imgUrl: require('@/assets/message-template-3.png')
				}
			],
			list: [],
			pagination: {
				current: 1,
				defaultPageSize: 15,
				showTotal: total => `共${total}条`
			},
			columns: [
				{
					title: '消耗类型',
					dataIndex: 'type',
					customRender: text => this.readDicFn(text / 1, this.typeOptions)
				},
				{
					title: '手机号',
					dataIndex: 'other.phone',
					customRender: text => {
						if (checkDataType(text, 'string')) return text
						if (checkDataType(text, 'array')) return text.join('，')
					}
				},
				{
					title: '发送时间',
					dataIndex: 'created_at'
				},
				{
					title: '短信使用条数',
					dataIndex: 'other.sms_count'
				},
				{
					title: '消耗金额(元)',
					dataIndex: 'money',
					customRender: text => (text / 100).toFixed(2)
				}
			],
			titleOptions: [
				{label: '审核中', value: 0},
				{label: '自定义短信', value: 1},
				{label: '审核失败', value: 2}
			],
			//0;//未指定消费类型
			//1;//订包间短信消费
			//2;//取消包间预订
			//3;//经营日报短信
			//4;//编辑包间订单短信消费
			//5;//转台
			//6;//开通专属服务经理
			//7;//顾客回复的短信发送给销售
			//8;//一键营销
			//9;//订包间扣除费用
			//10;//高德预订服务费
			//11;//高德预订由用户取消时返还服务费
			//12;//订包间短信回访
			//13;//订包间短信回访->处理完成短信模板
			typeOptions: [
				{value: 1, label: '预订短信'},
				{value: 2, label: '取消预订'},
				{value: 3, label: '经营日报'},
				{value: 4, label: '编辑包间'},
				{value: 5, label: '转台包间'},
				{value: 6, label: '开通专属服务经理'},
				{value: 7, label: '短信回复'},
				{value: 8, label: '短信营销'},
				{value: 9, label: '电子邀请函'},
				{value: 10, label: '高德预订'},
				{value: 12, label: '短信回访'},
				{value: 13, label: '短信回访处理完成通知'},
				{value: 15, label: '就餐提醒'},
				{value: 16, label: '生日提醒'}
			],
			corp_money: 0
		}
	},
	computed: {
		...mapState('user', ['info']),
		noteNum: function () {
			return Math.ceil(this.noteLength / 67)
		},
		noteMoney: function () {
			return Bigjs(this.noteNum).times(0.1)
		}
	},
	mounted() {
		this.getList()
		this.getQRCode()
		this.getMessageTemplate()
		this.getCustomBtns()
	},
	methods: {
		readDicFn,

		// 打开选择模板
		selectTemplate() {
			this.templateVisible = true
		},

		// 打开自定义模板
		openCustomModal() {
			this.customVisible = true

			this.$nextTick(() => {
				// this.getNbsp();
				// this.setCustomTemplate();
				this.setNoteLength()
			})
		},

		// 获取空格文本节点
		// getNbsp() {
		//   const customDom = this.$refs.customDom;
		//   const nbsp = customDom.firstChild;
		//   this.nbsp = nbsp;
		//   customDom.removeChild(nbsp);
		// },

		// 获取短信字数
		setNoteLength() {
			const contextDom = this.$refs.customDom
			// this.noteLength = contextDom.innerText.length + 6;
			this.noteLength = contextDom.value.length + 6
		},

		customCancel() {
			// const contextDom = this.$refs.customDom;
			// contextDom.innerHTML = '&nbsp;';
			// this.noteLength = contextDom.innerText.length;

			// this.nbsp = null; // 存储空节点
			this.customVisible = false // 自定义短信模板 visible
			this.customOkLoading = false // 自定义短信模板按钮loading
		},

		// 获取自定义模板btn列表
		getCustomBtns() {
			workSmsTemplateGet({type: 1}).then(res => {
				this.customBtn = (res.data.vars || []).map(item => ({
					label: item.var,
					value: item.text
				}))
				this.hasCustom = Boolean(res.data.template?.sms_content)
				// 模版审核状态
				this.customStatus = res.data.template?.status
				// 自定义短信模版+默认模版
				this.customInitTemplate = res.data.template.user_content || res.data.template.default
				// this.customInitTemplate =
				// 	res.data.template?.user_content ||
				// 	`贵宾，#顾客姓名##顾客性别# 您好！非常感谢您选择#餐厅名称#\n到店时间：#预订日期# #就餐类型#\n包间名称：#包间名称#\n厨师长推荐本店特色菜：麻辣小龙虾、老坛泡椒鱼头、汁水牛腩、原味肉汤、特色肉汤泡饭、水晶鸡、蒜蓉剁椒蒸生蚝\n专属服务经理：#服务经理姓名#\n服务经理电话：#服务经理电话#\n餐厅电话：#餐厅电话#\n餐厅地址：#餐厅地址#\n#餐厅名称#全体家人，恭候您的光临！\n`
				// this.customInitTemplate = res.data.template?.user_content || `
				//   <div>贵宾，&nbsp;#顾客姓名#&nbsp;&nbsp;#顾客性别#&nbsp;您好！&nbsp;非常感谢您选择&nbsp;#餐厅名称#&nbsp;</div>
				//   <div>到店时间：&nbsp;#预订日期#&nbsp;&nbsp;#就餐类型#&nbsp;</div>
				//   <div>包间名称：&nbsp;#包间名称#&nbsp;</div>
				//   <div>厨师长推荐本店特色菜：麻辣小龙虾、老坛泡椒鱼头、汁水牛腩、原味肉汤、特色肉汤泡饭、水晶鸡、蒜蓉剁椒蒸生蚝</div>
				//   <div>专属服务经理：&nbsp;#服务经理姓名#&nbsp;</div>
				//   <div>服务经理电话：&nbsp;#服务经理电话#&nbsp;</div>
				//   <div>餐厅电话：&nbsp;#餐厅电话#&nbsp;</div>
				//   <div>餐厅地址：&nbsp;#餐厅地址#&nbsp;</div>
				//   <div>&nbsp;#餐厅名称#&nbsp;全体家人，恭候您的光临！</div>
				// `;
			})
		},

		// 设置模板
		// setCustomTemplate() {
		//   const reg = /#([^#]*)#/g;
		//   let contextHtml = this.customInitTemplate.replace(reg, function (match, p1) {
		//     return `<span class='custom_underline' contenteditable='false'>#${p1}#</span>`;
		//   });
		//   const contextDom = this.$refs.customDom;
		//   contextDom.innerHTML = contextHtml;

		//   this.$nextTick(() => {
		//     this.setNoteLength();
		//   });
		// },

		// 粘贴事件
		// contextPaste(e) {
		//   e.preventDefault();
		//   var text =  (e.originalEvent || e).clipboardData.getData('text/plain');
		//   text = text.replace(/\[\d+\]|\n|\r/ig,"")
		//   // 在寻找 vue支持 navigator.clipboard.writeText(text); 的方法
		//   document.execCommand("insertText", false, text);
		// },

		// 键盘按下事件 - 控制删除
		// contextKeyDown(e) {
		//   // 禁止输入#
		//   if (e.keyCode === 51 && e.shiftKey) {
		//     e.preventDefault();
		//     return false;
		//   }

		//   // 删除按键按下触发
		//   if (e.keyCode === 8) {
		//     // 获取当前光标位置元素
		//     const selection = window.getSelection();
		//     const range = selection.getRangeAt(0);

		//     const currentNode = range.endContainer; // 当前元素
		//     const currentParentNode = currentNode.parentNode;
		//     const currentPreviousNode = currentNode.previousSibling;
		//     const onlyNbsp = /^\s{1}$/;
		//     const onlyString = /^\S{1}$/;

		//     // 上个节点为SPAN
		//     if (currentPreviousNode && currentPreviousNode.nodeName === 'SPAN') {
		//       if (onlyString.test(currentNode.nodeValue)) {
		//         e.preventDefault();
		//         currentParentNode.removeChild(currentNode);
		//         currentParentNode.appendChild(this.nbsp);
		//       }
		//       if (onlyNbsp.test(currentNode.nodeValue)) {
		//         e.preventDefault();
		//         currentParentNode.removeChild(currentPreviousNode);
		//         currentParentNode.removeChild(currentNode);
		//       }
		//     }

		//     // 去除不明规则出现的特殊span和br
		//     if (currentNode.nodeValue === null) currentNode.innerHTML = '';

		//     this.$nextTick(() => {
		//       this.setNoteLength();
		//     });
		//   }
		// },

		// 获取光标位置
		// getCursortPosition(element) {
		//   let caretOffset = 0;
		//   let selection= window.getSelection();
		//   if (selection.rangeCount > 0) {//选中的区域
		//       let range = selection.getRangeAt(0);
		//       let preCaretRange = range.cloneRange();//克隆一个选中区域
		//       preCaretRange.selectNodeContents(element);//设置选中区域的节点内容为当前节点
		//       preCaretRange.setEnd(range.endContainer, range.endOffset);  //重置选中区域的结束位置
		//       caretOffset = preCaretRange.toString().length;
		//   }
		//   return caretOffset;
		// },

		// 自定义模板按钮点击
		// customBtnClick(initword, executeKey = 'customDom') {
		//   const selection = window.getSelection();
		//   let range = selection.getRangeAt(0);
		//   const currentNode = range.endContainer;
		//   let customDom = null; // 获取dom

		//   if (currentNode.nodeName === '#text' && currentNode.parentNode.nodeName === 'DIV') {
		//     if (currentNode.parentNode.id === executeKey || currentNode.parentNode.parentNode.id === executeKey) {
		//       customDom = currentNode.parentNode;
		//     }
		//   }
		//   if (currentNode.nodeName === 'DIV') {
		//     if (currentNode.id === executeKey || currentNode.parentNode.id === executeKey) {
		//       customDom = currentNode;
		//     }
		//   }

		//   if (!customDom) return false;

		//   const customText = customDom.innerText;
		//   const customTextArr = customText.split('\n');
		//   const endOffset = this.getCursortPosition(customDom); // 获取下标
		//   let endOffsetHandle = endOffset;

		//   // 根据div换行符拆解设置内容
		//   for (let i = 0; i < customTextArr.length; i++) {
		//     if (endOffsetHandle > customTextArr[i].length) {
		//       endOffsetHandle -= customTextArr[i].length;
		//     } else {
		//       customTextArr[i] = insertStr(customTextArr[i], endOffsetHandle, '#😭#');
		//       break;
		//     }
		//   }

		//   // 根据拆解内容添加span和换行div
		//   let customHtml = '';
		//   customTextArr.forEach((item, index) => {
		//     let appendStr = '';
		//     if (index === 0) {
		//       appendStr = item.replace(/\s?#([^#]*)#\s?/g, function (match, p1) {
		//         if (p1 === '😭') return `&nbsp;<span class='custom_underline var' contenteditable='false'>${initword}</span>&nbsp;`;
		//         return `&nbsp;<span class='custom_underline' contenteditable='false'>#${p1}#</span>&nbsp;`;
		//       });
		//     } else {
		//       appendStr = (`<div>${item}</div>`).replace(/\s?#([^#]*)#\s?/g, function (match, p1) {
		//         if (p1 === '😭') return `&nbsp;<span class='custom_underline var' contenteditable='false'>${initword}</span>&nbsp;`;
		//         return `&nbsp;<span class='custom_underline' contenteditable='false'>#${p1}#</span>&nbsp;`;
		//       });
		//     }
		//     customHtml += appendStr;
		//   })

		//   range.deleteContents();

		//   customDom.innerHTML = customHtml;

		//   const offsetNode = customDom.getElementsByClassName('var')[0];
		//   offsetNode.classList.remove('var');
		//   const nextNbspNode = offsetNode.nextSibling;

		//   let frag = document.createDocumentFragment();
		//   let node;
		//   while ((node = customDom.firstChild)) {
		//     frag.appendChild(node);
		//   }

		//   range.insertNode(frag);
		//   range = range.cloneRange();
		//   range.setStart(nextNbspNode, 1);
		//   range.collapse(true);
		//   selection.removeAllRanges();
		//   selection.addRange(range);

		//   this.$nextTick(() => {
		//     this.setNoteLength();
		//   })
		// },

		// 按钮添加变量
		customBtnClickNew(initword, executeKey = 'customDom') {
			const customDom = this.$refs.customDom
			customDom.focus()
			const pre = customDom.value.substr(0, customDom.selectionStart)
			const post = customDom.value.substr(customDom.selectionEnd)
			customDom.value = pre + initword + post
		},

		// 上传自定义模板
		submitCustom() {
			const customDom = this.$refs.customDom
			let customText = customDom.value
			const customHtml = customText
			const _this = this
			const outKeyArr = ['预订日期', '服务经理电话', '餐厅电话', '餐厅地址', '预订邀请链接']
			customText = customText.replace(/#([^#]*)#/g, function (match, p1) {
				if (outKeyArr.includes(p1)) return ` ${_this.readDicFn(`#${p1}#`, _this.customBtn)} `
				return _this.readDicFn(`#${p1}#`, _this.customBtn)
			})

			this.customOkLoading = true
			workSmsTemplateUpadte({
				// sms_content: customText,
				user_content: customHtml
			})
				.then(res => {
					if (res.code === 0) {
						this.$message.success(res.msg || '成功')
						this.customCancel()
						this.getMessageTemplate()
						this.getCustomBtns()
					} else {
						this.$message.error(res.msg || '失败')
					}
				})
				.finally(() => {
					this.customOkLoading = false
				})
		},

		showAlert() {
			this.showInfo = true
			this.$nextTick(() => (this.$refs.alert1.closed = false))
		},
		visibleCancel() {
			this.qrCodeVisible = false
			this.qrLoading = false
		},
		getQRCode(refresh = 0) {
			this.qrLoading = true
			const _this = this
			// 拼装入参
			const params = {
				page: 'work_pages/pages/recharge/recharge',
				params: `shop_id=${_this.info.shop.id}`,
				width: 200
			}

			// 通过公共二维码获取接口获取二维码图片链接
			getWorkMakeMiniQrcode(params).then(res => {
				if (res.code === 0) {
					this.qrCode = res.data
					this.qrLoading = false
					if (refresh === 1) {
						this.$message.success('刷新成功！')
					}
				}
			})
		},

		// 获取模板消息
		getMessageTemplate() {
			getMessageTemplate().then(res => {
				if (res.code === 0) {
					this.templateChecked = this.templateList.find(item => item.id === res.data.sms_config.template_type)
				}
			})
		},

		// 设置使用的标准模板
		setMessageTemplate(templateType) {
			setMessageTemplate({template_type: templateType}).then(res => {
				if (res.code === 0) {
					this.getMessageTemplate()
					this.getCustomBtns()
					this.$message.success('使用成功！')
					this.templateVisible = false
				}
			})
		},
		getList(page = 1) {
			this.$store.dispatch('loading/startContentLoading')

			getTopupList({
				page,
				order_room_shop_id: this.$store.state.user.info.shop.id,
				luyou_shop_id: 0
			}).then(result => {
				if (result.code === 0) {
					this.corp_money = (result.data.corp_money / 100).toFixed(2)
					const res = result.data.corp_money_records
					const pagination = {...this.pagination}
					pagination.total = res.total
					this.list = res.data
					this.pagination = pagination
				}
				this.$store.dispatch('loading/endContentLoading')
			})
		},
		handleTableChange(pagination) {
			const pager = {...this.pagination}
			pager.current = pagination.current
			this.pagination = pager
			this.getList(pagination.current)
		}
	}
}
</script>

<style scoped lang="less">
.content-wrap {
	margin-top: 8px;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.dropDownMenu {
	height: 32px;
	line-height: 32px;
	font-size: 14px;
	padding: 0 11px;
	margin-left: 36px;
	background-color: #fff4f3 !important;
}

.customVisible {
	::v-deep {
		.ant-modal-footer {
			border-top: none;
		}
	}
	.custom_template {
		width: 535px;
		height: 250px;
		overflow-x: auto;
		border: 1px solid #ebebeb;
		border-radius: 6px;
		font-size: 13px;
		padding: 15px;
		.custom_template_title {
			border-left: 3px solid #e63e30;
			padding-left: 6px;
			font-size: 14px;
			height: 14px;
			line-height: 14px;
			font-weight: 500;
			color: #333;
		}
		.custom_template_space {
			padding: 12px 10px;
			button {
				margin: 0 8px 8px 0;
				font-size: 13px;
				height: 28px;
				line-height: 28px;
			}
		}
	}
	.custom_template_text {
		width: 535px;
		height: 250px;
		position: relative;
		textarea {
			display: block;
			width: 100%;
			height: 100%;
			padding: 15px;
			resize: none;
			font-size: 13px;
			color: #333;
			text-indent: 70px;
			border-radius: 6px;
			outline-style: none;
			border: 1px solid hsl(0, 0%, 92%);
			white-space: pre-wrap;
			word-break: break-word;
		}
	}
	.custom_template_text::before {
		content: attr(data-title);
		position: absolute;
		left: 10px;
		top: 15px;
		font-size: 13px;
		color: #333;
	}
	// .custom_template_left {
	//   position: relative;
	//   text-indent: 75px;
	//   color: #333;
	//   ::v-deep .custom_underline {
	//     color: #e63e30;
	//     display: inline-block;
	//     text-indent: 0;
	//   }
	//   ::v-deep {
	//     div {
	//       text-indent: 0;
	//     }
	//     div:first-child {
	//       text-indent: 75px;
	//     }
	//   }
	// }
	// .custom_template_left::before {
	//   content: attr(data-title);
	//   position: absolute;
	//   left: -65px;
	//   top: 15px;
	// }
	.custom_hint {
		width: 100%;
		border: 1px solid #ffd5d1;
		background-color: #fff4f3;
		border-radius: 6px;
		padding: 15px;
		font-family: PingFangSC-Regular;
		font-weight: 400;
		font-size: 13px;
		color: #333333;
		line-height: 22px;
		word-break: break-word;
	}
}

.desc {
	font-weight: 500;
	font-size: 20px;
	color: #333333;
}

.balance {
	font-weight: 600;
	font-size: 30px;
	color: #333333;
	display: flex;
	align-items: center;
	margin: 0 50px;
}

.balance > span {
	font-weight: 500;
	font-size: 20px;
	color: #333333;
	margin-left: 15px;
}

.table-warp {
	padding: 0 22px;
}

.table-title {
	font-weight: 600;
	font-size: 16px;
	color: #333333;
	margin-bottom: 20px;
}

.qr-code {
	width: 260px;
	background: #ffffff;
	border: 1px solid #ececec;
	border-radius: 2px;
	position: relative;
	box-sizing: border-box;
	padding: 10px;

	img {
		width: 100%;
		height: 100%;
	}

	.qr-code-mask {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.pay-tips {
	margin-top: 6px;
	width: 260px;
	height: 50px;
	font-size: 12px;
	color: #e63e30;
	line-height: 18px;
	background: #fff4f3;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7px 15px;
}

.message-template {
	position: relative;
}
.message-template-checked {
	position: absolute;
	z-index: 10;
	top: 26px;
	right: 21px;
	width: 34px;
	height: 34px;
}

.message-template-button {
	width: 120px;
	height: 42px;
}

// modal_tittle 自定义 modal 标题
.title_h1 {
	font-family: PingFangSC-Medium;
	font-weight: 500;
	font-size: 22px;
	color: rgba(0, 0, 0, 0.85);
	line-height: 24px;
	margin-bottom: 10px;
}

// modal_describe 自定义 modal 描述
.tips {
	font-family: PingFangSC-Regular;
	font-weight: 400;
	font-size: 16px;
	color: #e63e30;
	line-height: 18px;
}
</style>
